import React from "react";
import NextErrorPage from "next/error";

export default function Custom404() {
  return (
    <NextErrorPage
      statusCode={404}
      title="This page could not be found. Check the URL and try again."
    />
  );
}
